<div class="avatar-container">
  <ng-container *ngIf="!loading; else loadingTemplate">
    <ng-container *ngIf="src; else fallbackTemplate">
      <!--  Cannot get NgScr to work properly with this layout, feel free to try again -->
      <img
        class="avatar-image"
        [src]="src"
        [alt]="alt"
      />
    </ng-container>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="loadingblock avatar-placeholder"></div>
  </ng-template>

  <ng-template #fallbackTemplate>
    <div class="avatar-fallback avatar-placeholder">
    </div>
  </ng-template>

  <span *ngIf="showNotification" class="notifications-badge"></span>

  <div *ngIf="showMatchedBadge" class="matched-badge">
    <app-matched-by-ic></app-matched-by-ic>
  </div>
</div>
