<span
  matTooltipClass="mat-tooltip"
  class="mat-tooltip-trigger-icon-wrapper"
  matTooltip="{{'matched_by_ic.badge.tooltip' | translate}}"
  style="cursor: pointer;"
>
  <span class="tag is-rounded has-text-weight-medium is-small">{{
      'matched_by_ic.badge.text' | translate
    }}</span>
</span>
