import { ComponentFactoryResolver, Inject, Injectable } from '@angular/core';
import { OnlineSession } from 'src/app/entities/onlinesession.model';
import { User } from 'src/app/entities/user.model';
import { environment } from '../../../environments/environment';
import { Profile } from '../../entities/profile.model';
import { CallComponent } from '../video/call/call.component';
import { Appointment } from '../../entities/appointment.model';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  factoryResolver: any;
  rootViewContainer: any;
  videoComponent: any;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver) {
    this.factoryResolver = factoryResolver;
  }

  setVideoContainer(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  callIncoming(callId: string, currentUser: User, otherUser: User) {
    // Create the video component and set its inputs
    const factory = this.factoryResolver.resolveComponentFactory(CallComponent);
    this.videoComponent?.destroy();
    this.videoComponent = factory.create(this.rootViewContainer.parentInjector);
    this.videoComponent.instance.setCloseComponentFn(() =>
      this.videoComponent.destroy()
    );
    this.videoComponent.instance.callId = callId;
    this.videoComponent.instance.currentUser = currentUser;
    this.videoComponent.instance.otherUser = otherUser;
    this.rootViewContainer.insert(this.videoComponent.hostView);
  }

  generatePrivateCallId(therapistProfileId: Profile['id']) {
    // The link is private because we want therapists to be able to share this link
    // without having to wait for their therapist to join.
    // (see Robbie 20230129)
    return btoa(
      JSON.stringify({
        id: `call_private_${therapistProfileId}-${new Date().getTime()}`,
        kind: 'private',
        therapist: {
          profile: {
            id: therapistProfileId,
          },
        },
        known_clients: [],
      })
    );
  }

  generateAdHocWaitingRoomCallSlug(
    therapistProfileId: Profile['id'],
    session?: OnlineSession
  ) {
    return btoa(
      JSON.stringify({
        kind: 'adhoc',
        session_id: session?.id,
        therapist: {
          profile: {
            id: therapistProfileId,
          },
        },
      })
    );
  }

  generateAppointmentCallSlug(appointment: Appointment, profileId: number) {
    return btoa(
      JSON.stringify({
        kind: 'appointment',
        appointment: {
          uid: appointment.uid,
        },
        therapist: {
          profile: {
            id: profileId,
          },
        },
      })
    );
  }

  generateAdHocWithClientCallSlug(
    therapistProfileId: Profile['id'],
    clientId?: User['id']
  ) {
    return btoa(
      JSON.stringify({
        kind: 'adhoc',
        therapist: {
          profile: {
            id: therapistProfileId,
          },
        },
        known_clients: [{ id: clientId }],
      })
    );
  }

  generateJoinAdHocCallSlug(
    therapistProfileId: Profile['id'],
    myId: number,
    callId: string
  ) {
    return btoa(
      JSON.stringify({
        kind: 'adhoc',
        therapist: {
          profile: {
            id: therapistProfileId,
          },
        },
        known_clients: [{ id: myId }],
        call_id: callId,
      })
    );
  }

  navigateToGetstreamVideoCall(
    callSlug: string,
    targetCurrentWindow: boolean = false
  ) {
    const baseUrl = `${environment.baseUrl}/video/${callSlug}`;
    const paramsMap = {};
    const urlParams = new URLSearchParams(paramsMap);
    const params = urlParams.toString();

    // disable the alert "Changes that you made may not be saved."
    window.onbeforeunload = null;

    if (targetCurrentWindow) {
      window.location.href = baseUrl + (params ? `?${params}` : '');
    } else {
      window.open(baseUrl + (params ? `?${params}` : ''), '_blank');
    }
  }

  navigateToPrivateGetstreamVideoCall(therapistProfileId: Profile['id']) {
    const callId = this.generatePrivateCallId(therapistProfileId);
    this.navigateToGetstreamVideoCall(callId);
  }
}
