import { __assign, __awaiter, __extends, __generator } from "tslib";
import { getAnalyticsConnector, getCookieName, getPageViewTrackingConfig, getQueryParams, IdentityEventSender, isFileDownloadTrackingEnabled, isFormInteractionTrackingEnabled, isSessionTrackingEnabled, setConnectorDeviceId, setConnectorOptOut, setConnectorUserId } from '@amplitude/analytics-client-common';
import { AmplitudeCore, Destination, Identify, returnWrapper, Revenue, UUID } from '@amplitude/analytics-core';
import { pageViewTrackingPlugin } from '@amplitude/plugin-page-view-tracking-browser';
import { webAttributionPlugin } from '@amplitude/plugin-web-attribution-browser';
import { createCookieStorage, createTransport, getTopLevelDomain, useBrowserConfig } from './config';
import { DEFAULT_PAGE_VIEW_EVENT, DEFAULT_SESSION_END_EVENT, DEFAULT_SESSION_START_EVENT } from './constants';
import { parseLegacyCookies } from './cookie-migration';
import { Context } from './plugins/context';
import { defaultPageViewEventEnrichment } from './plugins/default-page-view-event-enrichment';
import { fileDownloadTracking } from './plugins/file-download-tracking';
import { formInteractionTracking } from './plugins/form-interaction-tracking';
import { convertProxyObjectToRealObject, isInstanceProxy } from './utils/snippet-helper';
var AmplitudeBrowser = /** @class */function (_super) {
  __extends(AmplitudeBrowser, _super);
  function AmplitudeBrowser() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  AmplitudeBrowser.prototype.init = function (apiKey, userId, options) {
    if (apiKey === void 0) {
      apiKey = '';
    }
    return returnWrapper(this._init(__assign(__assign({}, options), {
      userId: userId,
      apiKey: apiKey
    })));
  };
  AmplitudeBrowser.prototype._init = function (options) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _r, _s, _t, _u, _v, _w;
    return __awaiter(this, void 0, void 0, function () {
      var _x, _y, _z, legacyCookies, cookieStorage, previousCookies, queryParams, deviceId, sessionId, optOut, lastEventId, lastEventTime, userId, browserOptions, isNewSession, connector, webAttribution, pageViewTrackingOptions;
      var _this = this;
      return __generator(this, function (_0) {
        switch (_0.label) {
          case 0:
            // Step 0: Block concurrent initialization
            if (this.initializing) {
              return [2 /*return*/];
            }
            this.initializing = true;
            // Step 1: Read cookies stored by SDK
            _x = options;
            if (!options.disableCookies) return [3 /*break*/, 1];
            _y = '';
            return [3 /*break*/, 5];
          case 1:
            if (!((_a = options.domain) !== null && _a !== void 0)) return [3 /*break*/, 2];
            _z = _a;
            return [3 /*break*/, 4];
          case 2:
            return [4 /*yield*/, getTopLevelDomain()];
          case 3:
            _z = _0.sent();
            _0.label = 4;
          case 4:
            _y = _z;
            _0.label = 5;
          case 5:
            // Step 1: Read cookies stored by SDK
            _x.domain = _y;
            return [4 /*yield*/, parseLegacyCookies(options.apiKey, options)];
          case 6:
            legacyCookies = _0.sent();
            return [4 /*yield*/, createCookieStorage(options)];
          case 7:
            cookieStorage = _0.sent();
            return [4 /*yield*/, cookieStorage.get(getCookieName(options.apiKey))];
          case 8:
            previousCookies = _0.sent();
            queryParams = getQueryParams();
            deviceId = (_d = (_c = (_b = options.deviceId) !== null && _b !== void 0 ? _b : queryParams.deviceId) !== null && _c !== void 0 ? _c : previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.deviceId) !== null && _d !== void 0 ? _d : legacyCookies.deviceId;
            sessionId = (_e = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.sessionId) !== null && _e !== void 0 ? _e : legacyCookies.sessionId;
            optOut = (_g = (_f = options.optOut) !== null && _f !== void 0 ? _f : previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.optOut) !== null && _g !== void 0 ? _g : legacyCookies.optOut;
            lastEventId = (_h = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.lastEventId) !== null && _h !== void 0 ? _h : legacyCookies.lastEventId;
            lastEventTime = (_j = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.lastEventTime) !== null && _j !== void 0 ? _j : legacyCookies.lastEventTime;
            userId = (_l = (_k = options.userId) !== null && _k !== void 0 ? _k : previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.userId) !== null && _l !== void 0 ? _l : legacyCookies.userId;
            this.previousSessionDeviceId = (_m = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.deviceId) !== null && _m !== void 0 ? _m : legacyCookies.deviceId;
            this.previousSessionUserId = (_o = previousCookies === null || previousCookies === void 0 ? void 0 : previousCookies.userId) !== null && _o !== void 0 ? _o : legacyCookies.userId;
            return [4 /*yield*/, useBrowserConfig(options.apiKey, __assign(__assign({}, options), {
              deviceId: deviceId,
              sessionId: sessionId,
              optOut: optOut,
              lastEventId: lastEventId,
              lastEventTime: lastEventTime,
              userId: userId,
              cookieStorage: cookieStorage
            }))];
          case 9:
            browserOptions = _0.sent();
            return [4 /*yield*/, _super.prototype._init.call(this, browserOptions)];
          case 10:
            _0.sent();
            isNewSession = false;
            if (
            // user has never sent an event
            !this.config.lastEventTime ||
            // user has no previous session ID
            !this.config.sessionId ||
            // has sent an event and has previous session but expired
            this.config.lastEventTime && Date.now() - this.config.lastEventTime > this.config.sessionTimeout) {
              this.setSessionId((_r = (_p = options.sessionId) !== null && _p !== void 0 ? _p : this.config.sessionId) !== null && _r !== void 0 ? _r : Date.now());
              isNewSession = true;
            }
            connector = getAnalyticsConnector(options.instanceName);
            connector.identityStore.setIdentity({
              userId: this.config.userId,
              deviceId: this.config.deviceId
            });
            // Step 4: Install plugins
            // Do not track any events before this
            return [4 /*yield*/, this.add(new Destination()).promise];
          case 11:
            // Step 4: Install plugins
            // Do not track any events before this
            _0.sent();
            return [4 /*yield*/, this.add(new Context()).promise];
          case 12:
            _0.sent();
            return [4 /*yield*/, this.add(new IdentityEventSender()).promise];
          case 13:
            _0.sent();
            if (!isFileDownloadTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 15];
            return [4 /*yield*/, this.add(fileDownloadTracking()).promise];
          case 14:
            _0.sent();
            _0.label = 15;
          case 15:
            if (!isFormInteractionTrackingEnabled(this.config.defaultTracking)) return [3 /*break*/, 17];
            return [4 /*yield*/, this.add(formInteractionTracking()).promise];
          case 16:
            _0.sent();
            _0.label = 17;
          case 17:
            if (!!((_s = this.config.attribution) === null || _s === void 0 ? void 0 : _s.disabled)) return [3 /*break*/, 19];
            webAttribution = webAttributionPlugin({
              excludeReferrers: (_t = this.config.attribution) === null || _t === void 0 ? void 0 : _t.excludeReferrers,
              initialEmptyValue: (_u = this.config.attribution) === null || _u === void 0 ? void 0 : _u.initialEmptyValue,
              resetSessionOnNewCampaign: (_v = this.config.attribution) === null || _v === void 0 ? void 0 : _v.resetSessionOnNewCampaign
            });
            // For Amplitude-internal functionality
            // if pluginEnabledOverride === undefined then use plugin default logic
            // if pluginEnabledOverride === true then track attribution
            // if pluginEnabledOverride === false then do not track attribution
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            webAttribution.__pluginEnabledOverride = isNewSession || ((_w = this.config.attribution) === null || _w === void 0 ? void 0 : _w.trackNewCampaigns) ? undefined : false;
            return [4 /*yield*/, this.add(webAttribution).promise];
          case 18:
            _0.sent();
            _0.label = 19;
          case 19:
            pageViewTrackingOptions = getPageViewTrackingConfig(this.config);
            pageViewTrackingOptions.eventType = pageViewTrackingOptions.eventType || DEFAULT_PAGE_VIEW_EVENT;
            return [4 /*yield*/, this.add(pageViewTrackingPlugin(pageViewTrackingOptions)).promise];
          case 20:
            _0.sent();
            return [4 /*yield*/, this.add(defaultPageViewEventEnrichment()).promise];
          case 21:
            _0.sent();
            this.initializing = false;
            // Step 6: Run queued dispatch functions
            return [4 /*yield*/, this.runQueuedFunctions('dispatchQ')];
          case 22:
            // Step 6: Run queued dispatch functions
            _0.sent();
            // Step 7: Add the event receiver after running remaining queued functions.
            connector.eventBridge.setEventReceiver(function (event) {
              void _this.track(event.eventType, event.eventProperties);
            });
            return [2 /*return*/];
        }
      });
    });
  };
  AmplitudeBrowser.prototype.getUserId = function () {
    var _a;
    return (_a = this.config) === null || _a === void 0 ? void 0 : _a.userId;
  };
  AmplitudeBrowser.prototype.setUserId = function (userId) {
    if (!this.config) {
      this.q.push(this.setUserId.bind(this, userId));
      return;
    }
    if (userId !== this.config.userId || userId === undefined) {
      this.config.userId = userId;
      setConnectorUserId(userId, this.config.instanceName);
    }
  };
  AmplitudeBrowser.prototype.getDeviceId = function () {
    var _a;
    return (_a = this.config) === null || _a === void 0 ? void 0 : _a.deviceId;
  };
  AmplitudeBrowser.prototype.setDeviceId = function (deviceId) {
    if (!this.config) {
      this.q.push(this.setDeviceId.bind(this, deviceId));
      return;
    }
    this.config.deviceId = deviceId;
    setConnectorDeviceId(deviceId, this.config.instanceName);
  };
  AmplitudeBrowser.prototype.setOptOut = function (optOut) {
    setConnectorOptOut(optOut, this.config.instanceName);
    _super.prototype.setOptOut.call(this, optOut);
  };
  AmplitudeBrowser.prototype.reset = function () {
    this.setDeviceId(UUID());
    this.setUserId(undefined);
  };
  AmplitudeBrowser.prototype.getSessionId = function () {
    var _a;
    return (_a = this.config) === null || _a === void 0 ? void 0 : _a.sessionId;
  };
  AmplitudeBrowser.prototype.setSessionId = function (sessionId) {
    var _a;
    if (!this.config) {
      this.q.push(this.setSessionId.bind(this, sessionId));
      return;
    }
    // Prevents starting a new session with the same session ID
    if (sessionId === this.config.sessionId) {
      return;
    }
    var previousSessionId = this.getSessionId();
    var lastEventTime = this.config.lastEventTime;
    var lastEventId = (_a = this.config.lastEventId) !== null && _a !== void 0 ? _a : -1;
    this.config.sessionId = sessionId;
    this.config.lastEventTime = undefined;
    if (isSessionTrackingEnabled(this.config.defaultTracking)) {
      if (previousSessionId && lastEventTime) {
        this.track(DEFAULT_SESSION_END_EVENT, undefined, {
          device_id: this.previousSessionDeviceId,
          event_id: ++lastEventId,
          session_id: previousSessionId,
          time: lastEventTime + 1,
          user_id: this.previousSessionUserId
        });
      }
      this.config.lastEventTime = this.config.sessionId;
      this.track(DEFAULT_SESSION_START_EVENT, undefined, {
        event_id: ++lastEventId,
        session_id: this.config.sessionId,
        time: this.config.lastEventTime
      });
    }
    this.previousSessionDeviceId = this.config.deviceId;
    this.previousSessionUserId = this.config.userId;
  };
  AmplitudeBrowser.prototype.extendSession = function () {
    if (!this.config) {
      this.q.push(this.extendSession.bind(this));
      return;
    }
    this.config.lastEventTime = Date.now();
  };
  AmplitudeBrowser.prototype.setTransport = function (transport) {
    if (!this.config) {
      this.q.push(this.setTransport.bind(this, transport));
      return;
    }
    this.config.transportProvider = createTransport(transport);
  };
  AmplitudeBrowser.prototype.identify = function (identify, eventOptions) {
    if (isInstanceProxy(identify)) {
      var queue = identify._q;
      identify._q = [];
      identify = convertProxyObjectToRealObject(new Identify(), queue);
    }
    if (eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.user_id) {
      this.setUserId(eventOptions.user_id);
    }
    if (eventOptions === null || eventOptions === void 0 ? void 0 : eventOptions.device_id) {
      this.setDeviceId(eventOptions.device_id);
    }
    return _super.prototype.identify.call(this, identify, eventOptions);
  };
  AmplitudeBrowser.prototype.groupIdentify = function (groupType, groupName, identify, eventOptions) {
    if (isInstanceProxy(identify)) {
      var queue = identify._q;
      identify._q = [];
      identify = convertProxyObjectToRealObject(new Identify(), queue);
    }
    return _super.prototype.groupIdentify.call(this, groupType, groupName, identify, eventOptions);
  };
  AmplitudeBrowser.prototype.revenue = function (revenue, eventOptions) {
    if (isInstanceProxy(revenue)) {
      var queue = revenue._q;
      revenue._q = [];
      revenue = convertProxyObjectToRealObject(new Revenue(), queue);
    }
    return _super.prototype.revenue.call(this, revenue, eventOptions);
  };
  AmplitudeBrowser.prototype.process = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      var currentTime, lastEventTime, timeSinceLastEvent;
      return __generator(this, function (_a) {
        currentTime = Date.now();
        lastEventTime = this.config.lastEventTime || Date.now();
        timeSinceLastEvent = currentTime - lastEventTime;
        if (event.event_type !== DEFAULT_SESSION_START_EVENT && event.event_type !== DEFAULT_SESSION_END_EVENT && (!event.session_id || event.session_id === this.getSessionId()) && timeSinceLastEvent > this.config.sessionTimeout) {
          this.setSessionId(currentTime);
        }
        return [2 /*return*/, _super.prototype.process.call(this, event)];
      });
    });
  };
  return AmplitudeBrowser;
}(AmplitudeCore);
export { AmplitudeBrowser };
