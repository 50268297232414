import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from '../../components/alert/service/alert.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

/**
 *  Generic HTTP service
 *  For centralizing all error handling and minimizing code repetition.
 *
 *  For custom error handling, inject '@angular/core' HttpClient into service.
 */

@Injectable({
  providedIn: 'root',
})
export class GenericHttpService {
  constructor(
    public http: HttpClient,
    public alertService: AlertService,
    public router: Router
  ) {}

  API_URL: string = environment.apiBase;

  // Generic POST request
  public post<T = any>(
    path: string,
    item: any,
    options?: { headers?: HttpHeaders; params?: HttpParams }
  ): Observable<T> {
    return this.http.post<T>(this.API_URL + path, item, options);
  }

  // Generic PUT request
  public put<T = any>(
    path: string,
    item: any,
    options?: { headers?: HttpHeaders; params?: HttpParams }
  ): Observable<T> {
    return this.http.put<T>(this.API_URL + path, item, options);
  }

  // Generic DELETE request
  public delete<T = any>(
    path: string,
    options?: { headers?: HttpHeaders; params?: HttpParams }
  ): Observable<T> {
    return this.http.delete<T>(this.API_URL + path, options);
  }

  // Generic GET request
  public get<T = any>(
    path: string,
    options?: { headers?: HttpHeaders; params?: HttpParams }
  ): Observable<T> {
    return this.http.get<T>(this.API_URL + path, options);
  }
}
