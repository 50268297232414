// profile-image-helper.ts
import { environment } from 'src/environments/environment';

export type ImageVariant =
  | 'minimi'
  | 'mini'
  | 'thumbnail'
  | 'menu'
  | 'medium'
  | 'profile';

const sizes: Record<ImageVariant, number> = {
  minimi: 48,
  mini: 64,
  thumbnail: 90,
  menu: 200,
  medium: 240,
  profile: 520,
};

export function getProfileImageUrl(
  profile_id: number,
  variant: ImageVariant
): string | null {
  const base = environment.apiBase;
  const getSize = (str: ImageVariant) => sizes[str];

  return profile_id
    ? `${base}/imgprxy/${profile_id}/${getSize(variant)}/${getSize(variant)}`
    : null;
}
