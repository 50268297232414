import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupportRequest } from 'src/app/entities/supportReuest.model';
import { GenericHttpService } from './Generic HTTP/generic-http.service';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { CommunityEvent } from '../../entities/community-event.model';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  private eventsSubject = new BehaviorSubject<any[]>([]);
  private lastFetchTime: number = 0;
  private publicEventsSubject = new BehaviorSubject<any[]>([]);
  private lastPublicFetchTime: number = 0;
  private readonly refreshInterval = 2 * 60 * 1000; // time to cache the events in milliseconds

  constructor(private http: GenericHttpService) {}

  // upcoming events fm server, cached to avoid unnecessary requests
  getUpcomingEvents(): Observable<CommunityEvent[]> {
    const currentTime = new Date().getTime();

    if (currentTime - this.lastFetchTime > this.refreshInterval) {
      this.http
        .get('/upcoming_events/')
        .pipe(
          catchError((error) => {
            console.error('Error fetching events:', error);
            return [];
          }),
          tap((events) => {
            this.eventsSubject.next(events);
            this.lastFetchTime = new Date().getTime();
          })
        )
        .subscribe();
    }

    return this.eventsSubject.asObservable();
  }

  getUpcomingPublicEvents(): Observable<CommunityEvent[]> {
    const currentTime = new Date().getTime();

    if (currentTime - this.lastPublicFetchTime > this.refreshInterval) {
      this.http
        .get('/upcoming_public_events/')
        .pipe(
          catchError((error) => {
            console.error('Error fetching events:', error);
            return [];
          }),
          tap((events) => {
            this.publicEventsSubject.next(events);
            this.lastPublicFetchTime = new Date().getTime();
          })
        )
        .subscribe();
    }

    return this.publicEventsSubject.asObservable();
  }
}
