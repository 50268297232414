<div class="photo-uploader">
  <ngx-file-drop
    class="filedrop"
    dropZoneClassName="icdropzone"
    contentClassName="filedropcontent"
    (onFileDrop)="dropped($event)"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="fileInput">
      <app-photo-uploader-cropper
        (photoEmitter)="onPhotoEmitted($event)"
        [file]="previewImage"
        *ngIf="previewImage"
      >
      </app-photo-uploader-cropper>

      <div *ngIf="!previewImage">
        <div *ngIf="loadingPhoto" class="has-text-centered">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>Saving your image...</p>
        </div>

        <div *ngIf="!loadingPhoto" (click)="fileInput.click()">
          <svg
            width="32px"
            height="33px"
            viewBox="0 0 32 33"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Symbols"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="Upload-icon" transform="translate(1.000000, 1.000000)">
                <g id="Icon">
                  <g
                    id="Arrow"
                    transform="translate(15.500000, 15.500000) rotate(-90.000000) translate(-15.500000, -15.500000) translate(9.000000, 9.000000)"
                  >
                    <g
                      id="Group-5"
                      transform="translate(0.000000, 5.268309)"
                    ></g>
                    <polyline
                      id="Stroke-1"
                      stroke="#DADADA"
                      stroke-width="2"
                      points="6.51725455 11.921 12.8577091 6.17972727 6.51725455 0.438454545"
                    ></polyline>
                    <path
                      d="M12.3104091,6.17984545 L-0.000590909091,6.17984545"
                      id="Stroke-3"
                      stroke="#DADADA"
                      stroke-width="2"
                    ></path>
                  </g>
                  <path
                    d="M28.1517391,30.029689 L1.84956522,30.029689 C0.833478261,30.029689 0,29.2423244 0,28.2809498 L0,1.74873913 C0,0.787364548 0.833478261,0 1.84956522,0 L28.1517391,0 C29.1678261,0 30,0.787364548 30,1.74873913 L30,28.2809498 C30,29.2423244 29.1678261,30.029689 28.1517391,30.029689 Z"
                    id="Stroke-3"
                    stroke="#DADADA"
                    stroke-width="2"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          <h4 class="upload-title hidden-over">
            {{ "profile.modal.supporttext.pictureupload" | translate }}
          </h4>
          <h4 class="upload-title visible-over">
            {{ "profile.modal.supporttext.picturerelease" | translate }}
          </h4>
          <p>
            <span class="is-hidden-touch"
              >{{ "profile.modal.supporttext.draganddrop" | translate }}<br />{{
                "profile.modal.supporttext.or" | translate
              }}</span
            >&nbsp;<span class="choose">{{
              "profile.modal.supporttext.choose-file" | translate
            }}</span>
          </p>
          <p *ngIf="showPhotoSizeError">
            {{ "profile.modal.supporttext.photo-size" | translate }}
          </p>
          <input
            type="file"
            class="is-hidden"
            (change)="onFileChanged($event)"
            #fileInput
          />
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div
    class="photo-uploader-thumbnails"
    *ngIf="!previewImage && profile.images && !loadingPhoto"
  >
    <app-photo-uploader-thumbnail
      (onOrder)="orderImages($event)"
      [profile]="profile"
      [img]="img"
      [update]="updater"
      [index]="i"
      [count]="profile.images.length"
      *ngFor="let img of profile.images; let i = index"
    ></app-photo-uploader-thumbnail>
  </div>
</div>
