import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../../entities/user.model';
import { SharedService } from '../../shared/services/shared.service';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { AuthService, GenericError, IdToken } from '@auth0/auth0-angular';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AmplitudeAnalyticsService } from '../../shared/services/analytics/amplitude-analytics.service';
import { AuthLog } from './i-c-auth.service';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { AlertService } from '../../shared/components/alert/service/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAuth0Service {
  constructor(
    private authService: AuthService,
    private _http: HttpClient,
    private sharedService: SharedService,
    private genericHttp: GenericHttpService,
    private analytics: AmplitudeAnalyticsService,
    private alertService: AlertService
  ) {}

  loadCurrentUserData() {
    this.authService.isAuthenticated$
      .pipe(
        switchMap((isAuthenticated) => {
          AuthLog('Auth0 isAuthenticated$:', isAuthenticated);
          if (isAuthenticated) {
            return this.getCurrentUser();
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (user: User | null) => {
          this.sharedService.setCurrentUser(user);
        },
        (error) => {
          AuthLog('current_user is missing', error);
          this.sharedService.setCurrentUser(null);
          this.alertService.error(
            'common.generic_error.message',
            'common.generic_error.title',
            false,
            undefined,
            false
          );
          // if it is a 404, we might have been deleted, and should signout
          if (error.status === 404) {
            this.signOut();
          }
        }
      );
  }

  isUserSignedIn() {
    return this.authService.isAuthenticated$;
  }

  getCurrentUser(): Observable<User> {
    return this.genericHttp.get('/current_user').pipe(
      switchMap((userResponse) => {
        return this.getUserEmailVerifiedStatus(userResponse.user.email).pipe(
          map((emailVerified) => {
            return { ...userResponse.user, email_confirmed: emailVerified };
          })
        );
      })
    );
  }

  goToLogin(homeParams: Record<string, string>) {
    // tslint:disable-next-line:variable-name
    const screenHint = 'login';
    return this.goToLoginWithRedirect(homeParams, screenHint);
  }

  goToRegistration(homeParams: Record<string, string>) {
    // tslint:disable-next-line:variable-name
    const screenHint = 'signup';
    return this.goToLoginWithRedirect(homeParams, screenHint);
  }

  private goToLoginWithRedirect(
    homeParams: Record<string, string>,
    screenHint: string
  ) {
    // tslint:disable-next-line:variable-name
    const has_contact_intent = homeParams.ref !== undefined;
    const target = `/home?${new URLSearchParams(homeParams).toString()}`;
    const linkToken = homeParams.link_token;
    const email = homeParams.email;
    const connection = homeParams.connection;

    // wait for tracking to complete before redirecting
    return from(this.analytics.trackAuthStarted({ has_contact_intent }))
      .pipe(
        // tracking errors should not prevent login
        catchError(() => of(null))
      )
      .pipe(
        switchMap(() =>
          // login
          this.authService.loginWithRedirect({
            authorizationParams: {
              screen_hint: screenHint,
              audience: environment.auth0.audience,
              domain: environment.auth0.domain,
              redirect_uri: environment.appUrl + target,
              link_account_token: linkToken,
              login_hint: email,
              connection,
            },
            appState: {
              // See https://developer.auth0.com/resources/guides/spa/angular/basic-authentication#add-user-login-to-angular
              // target must be here, and above (at the time of writing)
              target,
              authContext: {
                has_contact_intent,
              },
            },
          })
        )
      );
  }

  init() {
    this.trackAuthFinished();
    this.handleAuthErrors();
  }

  handleAuthErrors() {
    // https://github.com/auth0/auth0-angular/blob/master/EXAMPLES.md#handling-errors
    this.authService.error$
      .pipe(tap((e) => MonitoringService.captureException(e)))
      .pipe(
        filter(
          (e) => e instanceof GenericError && e.error === 'login_required'
        ),
        mergeMap(() => {
          // TODO: should we track Auth Started?
          return this.goToLogin({});
        })
      )
      .subscribe(
        () => {
          AuthLog('handleAuthErrors ok');
        },
        (error) => {
          AuthLog('handleAuthErrors error:', error);
        }
      );
  }

  trackAuthFinished() {
    this.authService.appState$.subscribe((appState) => {
      if (appState.authContext) {
        // tslint:disable-next-line:variable-name
        const has_contact_intent =
          appState.authContext.has_contact_intent === true;
        this.analytics.trackAuthFinished({ has_contact_intent });
      }
    });
  }

  signOut(): void {
    this.authService
      .logout({
        logoutParams: {
          returnTo: environment.baseUrl + '/api/auth/logout',
          domain: environment.auth0.domain,
        },
      })
      .subscribe(
        () => {
          this.sharedService.setCurrentUser(null);
        },
        (error) => AuthLog('Logout failed', error)
      );
  }

  deleteAccount(): Observable<any> {
    return this.authService.logout({
      logoutParams: {
        // logout from new app too
        returnTo: environment.baseUrl + '/api/auth/logout',
        domain: environment.auth0.domain,
      },
    });
  }

  triggerPasswordChangeEmail(): Observable<any> {
    return this.genericHttp.get('/trigger_password_change_email');
  }

  /*
   XMLHttpRequest at 'https://complicated-life-dev.eu.auth0.com/api/v2/users/?q=email:%22undefined%22&search_engine=v3'
   from origin 'http://localhost:4200' has been blocked by CORS policy: Response to preflight request doesn't pass
   access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource.
  */
  getUserEmailVerifiedStatus(email: string): Observable<boolean> {
    AuthLog('getUserEmailVerifiedStatus', email);
    this.authService.user$.subscribe();
    return this.authService.idTokenClaims$
      .pipe(
        map((idToken: IdToken | undefined) => {
          return idToken && idToken.email_verified && idToken.email === email;
        })
      )
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((isVerified: boolean) => {
          AuthLog('verification isVerified', isVerified);
          if (isVerified) {
            return of(true);
          } else {
            return from(this.authService.getAccessTokenSilently()).pipe(
              switchMap((accessToken) => {
                AuthLog('verification accessToken', accessToken);
                return this._http
                  .get<{ email_verified: boolean }>(
                    `https://${environment.auth0.domain}/userinfo`,
                    {
                      headers: {
                        Authorization: `Bearer ${accessToken}`,
                      },
                    }
                  )
                  .pipe(
                    map((user) => {
                      AuthLog('verification user', user);
                      return user.email_verified;
                    })
                  );
              }),
              catchError((error) => {
                console.error('Error fetching user data:', error);
                return of(false);
              })
            );
          }
        })
      );
  }
}
