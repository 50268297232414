import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Profile } from 'src/app/entities/profile.model';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-photo-uploader-thumbnail',
  templateUrl: './photo-uploader-thumbnail.component.html',
  styleUrls: ['./photo-uploader-thumbnail.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class PhotoUploaderThumbnailComponent implements OnInit {
  constructor(private profileService: ProfileService) {}

  public API_URL: string = environment.apiBase;
  public isDeleting: boolean = false;

  @Input() img: any;
  @Input() index: number;
  @Input() count: number;
  @Input() profile: Profile;

  public timestamp: number = Date.now();

  @Output() onOrder: EventEmitter<any> = new EventEmitter();

  @Input() update: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.update.subscribe(() => {
      this.timestamp = Date.now();
    });
  }

  removePhoto(blob_id): void {
    this.isDeleting = true;
    this.profileService.removePhoto(blob_id).subscribe((res) => {
      this.profileService.updateItem(this.profile, this.profile.user.id);
    });
  }

  moveImg(index, direction) {
    this.onOrder.emit({ idx: index, dir: direction });
  }
}
