import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Profile } from '../../../entities/profile.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private _http: GenericHttpService,
    private httpClient: HttpClient
  ) {}

  API_URL: string = environment.apiBase;

  //For auto setting the location in profile map

  public currentLocation: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  //Behavior subjects for passing between components profile data and modal state

  private profileSource: BehaviorSubject<Profile> =
    new BehaviorSubject<Profile>(null);
  public profile: Observable<Profile> = this.profileSource.asObservable();

  private modalStateSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  public modalState = this.modalStateSource.asObservable();

  private therapistSource: BehaviorSubject<Profile> =
    new BehaviorSubject<Profile>(null);
  public therapist: Observable<Profile> = this.therapistSource.asObservable();

  private otherProfileSource: BehaviorSubject<Profile> =
    new BehaviorSubject<Profile>(null);
  public otherProfile: Observable<Profile> =
    this.otherProfileSource.asObservable();

  //Http requests

  setProfile = (profile: any) => this.profileSource.next(profile);
  getProfile = (slug): Subscription =>
    this._http
      .get('/profile/' + slug, { headers: headers })
      .subscribe(this.setProfile);

  setTherapist = (profile: any) => this.therapistSource.next(profile);
  getTherapist = (slug): Subscription =>
    this._http
      .get('/profile/' + slug, { headers: headers })
      .subscribe(this.setTherapist);

  // used for messaging
  setOtherProfile = (profile: any) => this.therapistSource.next(profile);
  getOtherProfile = (slug): Subscription =>
    this._http
      .get('/profile/' + slug, { headers: headers })
      .subscribe(this.setOtherProfile);

  getSelfProfile = (): Subscription =>
    this._http.get('/profile').subscribe(this.setProfile);

  getPublicProfile = (slug): Observable<Profile> =>
    this._http.get('/public/profile/' + slug, { headers: headers });

  setModalState = (state: boolean) => this.modalStateSource.next(state);

  updateItem = (item, user_id): Subscription => {
    return this._http
      .put('/profile/' + user_id, item)
      .subscribe(this.setProfile);
  };

  updateProfileSubscribable = (item, user_id): Observable<Profile> => {
    return this._http.put('/profile/' + user_id, item);
  };
  updateProfile(profile: Profile, profile_id: number) {
    return this._http.put('/profile/' + profile_id, profile);
  }

  uploadPhotos = (photos): Observable<any> =>
    this._http.post('/profile/images', photos);

  removePhoto = (blob_id): Observable<any> =>
    this._http.delete(`/profile/images/${blob_id}`);

  orderPhotos = (order): Observable<any> =>
    this._http.post(`/profile/images/order`, order);

  removeDiplomas = (blob_id): Observable<any> =>
    this._http.delete(`/profile/diplomas/${blob_id}`);

  uploadDiplomas = (file) => {
    const req = new HttpRequest(
      'POST',
      this.API_URL + '/profile/diplomas',
      file,
      { reportProgress: true }
    );

    return this.httpClient
      .request(req)
      .pipe(map((event) => this.handleProgress(event)));
  };

  addSessionOffer = (offer): Observable<any> =>
    this._http.post('/profile/offer', offer);
  removeSessionOffer = (id): Observable<any> =>
    this._http.delete('/profile/offer/' + id);
  updateSessionOffer = (offer): Observable<any> =>
    this._http.post('/profile/offer/' + offer.id, offer);

  updateAppointmentDuration = (profile: Profile): Observable<Profile> => {
    return this._http.post('/profile/appointment_duration', {
      duration: profile.appointment_duration,
      break: profile.appointment_break_duration,
    });
  };

  updateMinAppointmentNotificationTime = (
    profile: Profile
  ): Observable<Profile> => {
    return this._http.post('/profile/min_appointment_notification_time', {
      time: profile.min_appointment_notification_time,
    });
  };

  updatePausedInMatchingProgram = (paused: boolean): Observable<Profile> => {
    return this._http.post('/profile/paused_in_matching_program', {
      paused,
    });
  };

  updatePausedInWorkplaceProgram = (paused: boolean): Observable<Profile> => {
    return this._http.post('/profile/paused_in_eap_program', {
      paused,
    });
  };

  handleProgress(event) {
    if (event.type == HttpEventType.UploadProgress) {
      const percentDone = Math.round((100 * event.loaded) / event.total);
      return { progress: percentDone };
    } else if (event.type == HttpEventType.Response) {
      return { status: 'done', id: event.body.blob_id };
    }
  }

  getCategories = (): Observable<any> =>
    this._http.get('/categories', { headers: headers });

  getJobTitles = (): Observable<any> =>
    this._http.get('/jobtitles', { headers: headers });
  getLocalisedJobTitles = (): Observable<any> =>
    this._http.get('/localised_jobtitles', { headers: headers });
  getLanguages = (): Observable<any> =>
    this._http.get('/languages', { headers: headers });
  getIdentities = (): Observable<any> =>
    this._http.get('/identities', { headers: headers });
  getGeocode = (location): Observable<any> =>
    this._http.get('/geocode?location=' + location, { headers: headers });

  updateUsername = (username): Observable<any> =>
    this._http.post('/profile/username', { username: username });
}
