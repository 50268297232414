import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { InviteClient } from 'src/app/entities/invite-client.model';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class InviteClientsService {
  constructor(private http: GenericHttpService) {}

  API_URL: string = environment.apiBase;

  private shouldShowModalSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  public shouldShowModal = this.shouldShowModalSource.asObservable();

  openModal = () => {
    this.shouldShowModalSource.next(true);
  };
  closeModal = () => {
    this.shouldShowModalSource.next(false);
  };

  inviteClient = (invite: InviteClient): Observable<any> =>
    this.http.post('/invite_client', invite);
}
