import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthAuth0Service } from './auth-auth0.service';
import { BehaviorSubject, of } from 'rxjs';
import { ResetPassword } from '../reset-password/reset-password.component';
import { HttpHeaders } from '@angular/common/http';
import { GenericHttpService } from '../../shared/services/Generic HTTP/generic-http.service';
import { authGuardFn } from '@auth0/auth0-angular';
import { preApprovalGuard } from '../../app/pre-approval.guard';
import { notificationGuard } from '../../shared/services/notifications/notifications.guard';
import { switchMap } from 'rxjs/operators';
import { ChatService } from '../../components/messaging/service/chat.service';
import { ChatClientService } from 'stream-chat-angular';

@Injectable({
  providedIn: 'root',
})
export class ICAuthService {
  constructor(
    private auth0AuthService: AuthAuth0Service,
    private genericHttp: GenericHttpService
  ) {
    this.auth0AuthService.loadCurrentUserData();
    this.auth0AuthService.isUserSignedIn().subscribe((isAuthenticated) => {
      this.isLoggedInSource.next(isAuthenticated);
    });
  }

  private isLoggedInSource = new BehaviorSubject<boolean>(null);
  isLoggedIn = this.isLoggedInSource.asObservable();

  setLoggedIn(isLoggedIn: boolean) {
    this.isLoggedInSource.next(isLoggedIn);
  }

  updatePassword(resetPassword: ResetPassword, headers: HttpHeaders) {
    return this.genericHttp.put('/auth/password', resetPassword, {
      headers,
    });
  }

  getCurrentUser() {
    return this.auth0AuthService.getCurrentUser();
  }

  resendWelcomeMail() {
    return this.genericHttp.get('/resend_welcome_email');
  }

  signOut() {
    return this.auth0AuthService.signOut();
  }

  deleteAccount() {
    return this.auth0AuthService.deleteAccount();
  }

  triggerPasswordChangeEmail() {
    return this.auth0AuthService.triggerPasswordChangeEmail();
  }

  goToLogin(homeParams: Record<string, string>) {
    return this.auth0AuthService.goToLogin(homeParams);
  }

  initAuth() {
    this.auth0AuthService.init();
  }

  goToRegistration(homeParams: Record<string, string>) {
    return this.auth0AuthService.goToRegistration(homeParams);
  }
}

export const AuthCanActivate = [
  authGuardFn,
  preApprovalGuard,
  notificationGuard,
];

export function AuthLog(...data: any[]) {
  if (environment.isAuthLoggingEnabled) {
    console.log('DEBUG AUTH', ...data);
  }
}
