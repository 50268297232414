import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CommunityService } from '../../../shared/services/community.service';
import { CommunityEvent } from '../../../entities/community-event.model';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-upcoming-community-events',
  templateUrl: './upcoming-community-events.component.html',
  styleUrls: ['./upcoming-community-events.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TranslateModule, AsyncPipe, DatePipe],
})
export class UpcomingCommunityEventsComponent implements OnInit {
  events$: Observable<CommunityEvent[]>;
  @Input() showPublicEvents: boolean = false;

  constructor(private communityService: CommunityService) {}

  ngOnInit(): void {
    this.events$ = this.showPublicEvents
      ? this.communityService.getUpcomingPublicEvents()
      : this.communityService.getUpcomingEvents();
  }
}
