<h5 *ngIf="index == 0">Profile Image:</h5>
<h5 *ngIf="index == 1">Additional Slideshow Images:</h5>
<div class="profile-image-small-view" [ngClass]="{ deleting: isDeleting }">
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <img
    [src]="
      API_URL +
      '/imgprxyindex/' +
      profile.id +
      '/' +
      index +
      '/150/150?t=' +
      timestamp
    "
    class="profile-image-small"
  />
  <span class="profile-image-small-title" *ngIf="!isDeleting" title="Move up">{{
    img.blob.filename
  }}</span>
  <span class="profile-image-small-title" *ngIf="isDeleting" title="Move down"
    >Deleting this image…</span
  >
  <a class="delete" (click)="removePhoto(img.blob.id)" *ngIf="!isDeleting"></a>
  <a class="moveup" (click)="moveImg(index, -1)" *ngIf="index != 0">▲</a>
  <a class="movedown" (click)="moveImg(index, 1)" *ngIf="index < count - 1"
    >▼</a
  >
</div>
