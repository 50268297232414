import { ErrorHandler } from '@angular/core';
import { environment } from '../../../../environments/environment';

import * as Sentry from '@sentry/angular';
import { User } from '../../../entities/user.model';
import { CaptureContext, SeverityLevel } from '@sentry/types';

export class MonitoringService {
  constructor() {}

  static init() {
    if (environment.sentry) {
      Sentry.init({
        dsn: 'https://6f8be92ab73f459a9de35f54449ebb01@o4505606794379264.ingest.sentry.io/4504685782237184',
        integrations: [
          // new CaptureConsoleIntegration({
          //   levels: ['warn', 'error'],
          // }),
          Sentry.httpClientIntegration({
            // This array can contain tuples of `[begin, end]` (both inclusive),
            // single status codes, or a combination of both.
            // default: [[500, 599]]
            failedRequestStatusCodes: [400, 599],
            // This array can contain Regexes, strings, or a combination of both.
            // default: [/.*/]
            failedRequestTargets: [/(staging\.)?complicated\.life/],
          }),
        ],
        environment: environment.name,
        // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/filtering/#decluttering-sentry
        ignoreErrors: [
          'Non-Error exception captured',
          // Random plugins/extensions
          'top.GLOBALS',
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          'originalCreateNotification',
          'canvas.contentDocument',
          'MyApp_RemoveAllHighlights',
          'http://tt.epicplay.com',
          "Can't find variable: ZiteReader",
          'jigsaw is not defined',
          'ComboSearch is not defined',
          'http://loading.retry.widdit.com/',
          'atomicFindClose',
          // Facebook borked
          'fb_xd_fragment',
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
          // reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268
          'bmi_SafeAddOnload',
          'EBCallBackMessageReceived',
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          'conduitPage',
        ],
        denyUrls: [
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
      });
    }
  }

  static createGlobalErrorHandler() {
    if (environment.sentry) {
      return Sentry.createErrorHandler({
        showDialog: environment.production === false,
      });
    } else {
      return new DevErrorHandler();
    }
  }

  static setUser(user: User) {
    if (environment.sentry) {
      // https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
      Sentry.setUser({
        id: `${user.id}`,
        email: user.email,
      });
    }
  }

  static clearUser() {
    if (environment.sentry) {
      Sentry.setUser(null);
    }
  }

  static captureException(exception: any, captureContext?: CaptureContext) {
    if (environment.sentry) {
      Sentry.captureException(exception, captureContext);
    } else {
      console.error('[ERROR (captured)]', exception);
    }
  }

  static captureMessage(
    message: string,
    captureContext?: CaptureContext | SeverityLevel
  ) {
    if (environment.sentry) {
      Sentry.captureMessage(message, captureContext);
    } else {
      console.error('[MESSAGE (captured)]', message);
    }
  }
}

class DevErrorHandler implements ErrorHandler {
  handleError(error) {
    const data = error ? error.originalError || error : 'Unknown error';
    console.error('[ERROR (global)]', data);
  }
}
