import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-matched-by-ic',
  templateUrl: './matched-by-ic.component.html',
  styleUrls: ['./matched-by-ic.component.scss'],
  standalone: true,
  imports: [MatTooltip, TranslateModule],
})
export class MatchedByIcComponent implements OnInit {
  constructor(private injector: Injector, private router: Router) {}

  ngOnInit() {}
}
