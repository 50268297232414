export class Alert {
  type: AlertType;
  title?: string;
  message: string;
  buttons?: any;
  autoHide: boolean;
}

export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFO',
  ERROR = 'ERROR',
}
