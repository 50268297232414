<aside *ngIf="currentUser">
  <section class="profile-top-section">
    <div class="columns is-mobile is-multiline">
      <div class="column is-4-touch is-12-desktop">
        <div class="profile-picture-container">
          <ng-container *ngIf="!showUploader">
            <app-profile-picture
              (click)="showProfile()"
              [variant]="'profile'"
              [profile_id]="currentUser.profile.id"
            ></app-profile-picture>
            <div class="profile-picture-overlay">
              <button
                (click)="showUploader = true"
                *ngIf="
                  currentUser.type == 'Client' &&
                  currentUser.profile.images?.length == 0
                "
                class="upload-button button is-rounded is-outlined"
              >
                {{ "navigation.upload-a-picture" | translate }}
              </button>
            </div>
          </ng-container>
        </div>
        <div class="profile-picture-upload-container" *ngIf="showUploader">
          <app-photo-uploader
            (closeModal)="showUploader = false"
            [profile]="currentUser.profile"
            [currentUser]="currentUser"
          ></app-photo-uploader>
        </div>
      </div>
      <div class="column is-8-touch is-12-desktop profile-and-dropdown">
        <h2 *ngIf="currentUser.name" class="profile-name">
          {{ currentUser.name.split(" ")[0] }}
        </h2>

        <h4 *ngIf="currentUser.profile && !isSettings" class="profile-jobtitle">
          {{ currentUser.profile.jobtitle }}
        </h4>
        <h4 *ngIf="isSettings" class="profile-jobtitle is-hidden-touch">
          {{ "navigation.settings" | translate }}
        </h4>

        <div
          *ngIf="isSettings"
          class="dropdown is-fullwidth is-hidden-tablet is-hidden-desktop settings-dropdown"
          [ngClass]="{ 'is-active': isMobileSettingsDropdownOpen }"
        >
          <div class="dropdown-trigger">
            <button
              class="button is-rounded"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              (click)="
                isMobileSettingsDropdownOpen = !isMobileSettingsDropdownOpen
              "
            >
              <ng-container *ngIf="currentSettingsSection == 'settings'">
                <span class="icon icon-settings"></span>
                <span>{{ "navigation.account" | translate }}</span>
              </ng-container>
              <ng-container *ngIf="currentSettingsSection == 'calendar'">
                <span class="icon icon-calendar"></span>
                <span>{{ "navigation.calendar" | translate }}</span>
              </ng-container>
              <ng-container *ngIf="currentSettingsSection == 'widgets'">
                <span class="icon icon-share-gray"></span>
                <span>{{ "navigation.widgets" | translate }}</span>
              </ng-container>
              <ng-container *ngIf="currentSettingsSection == 'subscription'">
                <span class="icon icon-subscription"></span>
                <span>{{ "navigation.subscription" | translate }}</span>
              </ng-container>
              <!-- <ng-container *ngIf="currentSettingsSection =='notifications'">
                              <span class="icon icon-notifications"></span>
                              <span>Notifications</span>
                            </ng-container> -->
              <span class="icon is-small">
                <i class="icon icon-chevron-down-green" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a class="dropdown-item" routerLink="/home">
                <span class="icon icon-dashboard"></span
                >{{ "navigation.dashboard" | translate }}
              </a>
              <a class="dropdown-item" routerLink="/home/settings">
                <span class="icon icon-settings"></span
                >{{ "navigation.account" | translate }}
              </a>
              <ng-container *ngIf="currentUser.type == 'Therapist'">
                <a class="dropdown-item" routerLink="/home/settings/calendar">
                  <span class="icon icon-calendar"></span
                  >{{ "navigation.calendar" | translate }}
                </a>
                <a class="dropdown-item" routerLink="/home/settings/widgets">
                  <span class="icon icon-share-gray"></span
                  >{{ "navigation.widgets" | translate }}
                </a>
                <a
                  class="dropdown-item"
                  routerLink="/home/settings/subscription"
                >
                  <span class="icon icon-subscription"></span
                  >{{ "navigation.subscription" | translate }}
                </a>
              </ng-container>
            </div>
          </div>
        </div>

        <ng-container
          *ngIf="
            currentUser.type == 'Therapist' &&
            currentUser.profile.profile_approved &&
            !isSettings
          "
        >
          <div style="margin-bottom: 0.5em">
            <ng-container
              class="control"
              *ngIf="
                currentUser.profile.stripe_connect_active == true;
                else elseBlock
              "
            >
              <a
                class="button is-rounded profile-share-button mb-1"
                title="Create Invoice"
                *ngIf="
                  currentUser &&
                  currentUser.type == 'Therapist' &&
                  currentUser.profile.stripe_connect_active == true
                "
                (click)="createNewInvoice()"
                id="sidenav-button"
              >
                <span class="icon icon-note-primary"></span>
                <span>{{ "office.actions.create_invoice" | translate }}</span>
              </a>
            </ng-container>
            <ng-template
              #elseBlock
              *ngIf="
                currentUser &&
                currentUser.type == 'Therapist' &&
                currentUser.profile.country &&
                this.env.stripe_available_countries.includes(
                  currentUser.profile.country
                )
              "
            >
              <a
                routerLink="/office/settings"
                class="button is-rounded profile-share-button mb-1"
                id="sidenav-button"
              >
                <span class="icon icon-edit-primary"></span>
                <span>{{ "registration.activate-invoice" | translate }}</span>
              </a>
            </ng-template>

            <a
              (click)="showInviteClientsModal()"
              class="button is-rounded profile-share-button mb-1"
              *ngIf="currentUser.profile.availability !== 'profile_hidden'"
              id="sidenav-button"
            >
              <span class="icon icon-invite-client-primary"></span>
              <span>{{ "navigation.invite_clients" | translate }}</span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <hr class="menu-list-hr is-hidden-touch" />

  <ng-container *ngIf="!isSettings">
    <!-- Therapist navigation -->
    <ng-container *ngIf="currentUser.type == 'Therapist'">
      <ul class="menu-list">
        <li>
          <a routerLink="/home">
            <span class="icon icon-dashboard"></span>
            {{ "navigation.dashboard" | translate }}
          </a>
        </li>
        <li>
          <a class="is-flex align-center" (click)="openCommunity()">
            <span class="icon icon-community"></span>
            <span>{{ "navigation.community" | translate }}</span>
          </a>
        </li>
        <li *ngIf="myOfficeAvailable">
          <a
            [routerLink]="
              currentUser.profile.stripe_connect_has_currently_due_requirements
                ? ['/office/settings']
                : ['/office/dashboard']
            "
          >
            <span class="icon icon-invoice"></span>
            <span
              [ngClass]="{
                'attention is-align-items-baseline': hasInvoiceAttentionBanner
              }"
            >
              {{ "navigation.office" | translate }}</span
            >
          </a>
        </li>
        <li *ngIf="myOfficeNotSupported">
          <!-- the office settings will tell them why it is not supported-->
          <a routerLink="/office/settings">
            <span class="icon icon-invoice"></span>
            {{ "navigation.office" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/calendar">
            <span class="icon icon-calendar"></span>
            {{ "navigation.calendar" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/my_clients">
            <span class="icon icon-my-clients"></span>
            {{ "navigation.clients" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/messaging" style="position: relative">
            <span class="icon icon-messages">
              <span
                class="notifications-badge navigation-badge"
                *ngIf="unreadMessagesCount > 0"
              >{{ unreadMessagesCount }}</span
              >
            </span>
            {{ "navigation.messages" | translate }}
          </a>
        </li>
        <li>
          <a class="is-flex align-center" routerLink="/home/video-waiting-room" style="position: relative">
            <span class="icon icon-waiting_room"></span>
            {{ "navigation.waiting_room" | translate }}
            <span
              class="tag is-primary is-rounded has-margin-left-15 has-text-weight-medium"
              style="border-radius: 6px"
            >{{ "navigation.new" | translate }}</span
            >
          </a>
        </li>
        <li *ngIf="env.name != 'production'">
          <a routerLink="/practice">
            <span class="icon icon-my-clients"></span>
            {{ "navigation.practice" | translate }}&nbsp;&nbsp;
            <p class="help">🧪</p>
          </a>
        </li>
        <li>
          <a routerLink="/home/invites">
            <span class="icon icon-referral-program"></span
            >{{ "navigation.refer_colleague" | translate }}
          </a>
        </li>
        <li>
          <a
            routerLink="/home/settings/widgets"
            *ngIf="currentUser.profile.availability !== 'profile_hidden'"
          >
            <span class="icon icon-share-gray"></span
            >{{ "navigation.social_widgets" | translate }}
          </a>
        </li>
      </ul>
      <hr class="menu-list-hr" />
      <ul class="menu-list">
        <li>
          <a [routerLink]="['/home/profile/']">
            <span class="icon icon-edit-profile"></span>
            {{ "navigation.edit_profile" | translate }}
          </a>
        </li>
        <li *ngIf="env.name != 'production'">
          <a (click)="openProfileEditorV2()">
            <span class="icon icon-edit-profile"></span>
            {{ "navigation.edit_profile_v2" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/settings/subscription">
            <span class="icon icon-subscription"></span
            >{{ "navigation.subscription" | translate }}
          </a>
        </li>
        <li routerLink="/home/settings">
          <a>
            <span class="icon icon-settings"></span>
            {{ "navigation.settings" | translate }}
          </a>
        </li>
        <li>
          <a (click)="requestSupport()">
            <span class="icon icon-feedback"></span>
            {{ "navigation.support" | translate }}
          </a>
        </li>
      </ul>
      <hr class="menu-list-hr" />
      <ul class="menu-list">
        <li>
          <a
            class="is-flex align-center"
            href="{{ env.baseUrl + '/find-a-therapist' }}"
          >
            <span class="icon icon-list"></span>
            <span>{{ "navigation.directory" | translate }}</span>
          </a>
        </li>
        <li *ngIf="currentUser.profile.profile_approved">
          <a href="{{ currentUser.profile.link }}" target="_blank">
            <span class="icon icon-my-clients"></span>
            {{ "navigation.view_profile" | translate }}
          </a>
        </li>
      </ul>
      <hr class="menu-list-hr" />
      <app-upcoming-community-events *ngIf="this.shouldShowUpcomingEvents()"
                                     [showPublicEvents]="false"
      />
    </ng-container>


    <!-- Client navigation -->
    <ng-container *ngIf="currentUser.type == 'Client'">
      <ul *ngIf="currentUser.type == 'Client'" class="menu-list">
        <li>
          <a routerLink="/home">
            <span class="icon icon-dashboard"></span>
            {{ "navigation.dashboard" | translate }}
          </a>
        </li>
        <li>
          <a class="is-flex align-center" (click)="openCommunity()">
            <span class="icon icon-community"></span>
            <span>{{ "navigation.community" | translate }}</span>
            <span
              class="tag is-primary is-rounded has-margin-left-5 has-text-weight-medium"
              style="border-radius: 6px"
            >{{ "navigation.new" | translate }}</span
            >
          </a>
        </li>
        <li>
          <a routerLink="/home/messaging" style="position: relative">
            <span class="icon icon-messages">
              <span
                class="notifications-badge navigation-badge"
                *ngIf="messageNotifications > 0"
                >{{ messageNotifications }}</span
              >
            </span>
            {{ "navigation.messages" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/calendar">
            <span class="icon icon-calendar"></span>
            {{ "navigation.calendar" | translate }}
          </a>
        </li>
        <li *ngIf="env.name != 'production'">
          <a routerLink="/home/settings/profile">
            <span class="icon icon-my-clients"></span
            >{{ "client_side.my_profile" | translate }}&nbsp;&nbsp;🧪</a
          >
        </li>
        <li routerLink="/home/settings">
          <a>
            <span class="icon icon-settings"></span>
            {{ "navigation.settings" | translate }}
          </a>
        </li>
        <li (click)="showInviteClientsModal()">
          <a>
            <span class="icon icon-invite-client"></span>
            {{ "navigation.tell_a_friend" | translate }}
          </a>
        </li>
      </ul>
      <hr class="menu-list-hr" />
      <ul class="menu-list">
        <li>
          <a
            class="is-flex align-center"
            href="{{ env.baseUrl + '/find-a-therapist' }}"
          >
            <span class="icon icon-list"></span>
            <span>{{ "common.find-a-therapist" | translate }}</span>
          </a>
        </li>
        <li>
          <a routerLink="/home/favourites">
            <span class="icon icon-favorite"></span>
            {{ "navigation.my_favorite_therapists" | translate }}
          </a>
        </li>
        <li>
          <a target="_blank" href="https://form.typeform.com/to/aXc8huRS">
            <span class="icon icon-feedback"></span>
            {{ "navigation.feedback" | translate }}
          </a>
        </li>
        <li>
          <a (click)="requestSupport()">
            <span class="icon icon-feedback"></span>
            {{ "navigation.support" | translate }}
          </a>
        </li>
        <li>
          <a (click)="logout()">
            <span class="icon icon-logout"></span
            >{{ "common.logout" | translate }}
          </a>
        </li>
      </ul>
      <hr class="menu-list-hr" />
      <app-upcoming-community-events *ngIf="this.shouldShowUpcomingEvents()"
                                     [showPublicEvents]="true"
      />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isSettings">
    <ul class="menu-list is-hidden-touch">
      <li>
        <a routerLink="/home">
          <span class="icon icon-dashboard"></span
          >{{ "navigation.dashboard" | translate }}
        </a>
      </li>
      <li>
        <a routerLink="/home/settings">
          <span class="icon icon-settings"></span
          >{{ "navigation.account" | translate }}
        </a>
      </li>
      <ng-container *ngIf="currentUser.type == 'Therapist'">
        <li>
          <a routerLink="/home/settings/calendar">
            <span class="icon icon-calendar"></span
            >{{ "navigation.calendar_settings" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/settings/programs">
             <span class="icon"><mat-icon class="material-symbols-outlined" style="color: #5D6872"
            >hotel_class</mat-icon
             ></span>
            {{ "navigation.program_settings" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/settings/widgets">
            <span class="icon icon-share-gray"></span
            >{{ "navigation.social_widgets" | translate }}
          </a>
        </li>
        <li>
          <a routerLink="/home/settings/subscription">
            <span class="icon icon-subscription"></span
            >{{ "navigation.subscription" | translate }}
          </a>
        </li>
      </ng-container>
      <li>
        <!-- <a routerLink="/home/settings/notifications">
                    <span class="icon icon-notifications"></span>Notifications
                </a>
            </li> -->
      </li>
    </ul>
  </ng-container>

<!--  Commenting this out as it messes with the design too much and is not that useful right now-->
<!--  <ng-container *ngIf="isDevMenuEnabled">-->
<!--    <hr class="menu-list-hr" />-->
<!--    <h4>👾 Development Tools</h4>-->
<!--    <ul class="menu-list">-->
<!--      <li>-->
<!--        <a class="is-flex align-center" (click)="testTriggerUnauthorized()">-->
<!--          <span class="icon">🔒</span>-->
<!--          <span>HTTP 401</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li>-->
<!--        <a class="is-flex align-center" (click)="testCaptureSentryMessage()">-->
<!--          <span class="icon">🔒</span>-->
<!--          <span>Test Sentry Message</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </ng-container>-->
</aside>
