import { Injectable } from '@angular/core';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Invoice } from 'src/app/entities/invoice.model';
import { InvoiceClient } from 'src/app/entities/invoice-client.model';
import { INVOICE_ACTIONS } from '../models/invoice_actions.enum';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  constructor(private http: GenericHttpService) {}

  private stripeAccountSource: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private stripeCurrentlyDueAccountLinksSource: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  private stripeEventuallyDueAccountLinksSource: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  private stripeConnectClientSecretSource: BehaviorSubject<
    string | null | undefined
  > = new BehaviorSubject<string | null | undefined>(undefined);
  public stripeConnectAccount: Observable<any> =
    this.stripeAccountSource.asObservable();

  public stripeCurrentlyDueAccountLinks: Observable<any> =
    this.stripeCurrentlyDueAccountLinksSource.asObservable();

  public stripeEventuallyDueAccountLinks: Observable<any> =
    this.stripeEventuallyDueAccountLinksSource.asObservable();

  public stripeConnectClientSecret: Observable<any> =
    this.stripeConnectClientSecretSource.asObservable();

  // Account Creation
  setStripeConnectAccount = (stripeConnectAccount: any) =>
    this.stripeAccountSource.next(stripeConnectAccount);
  loadStripeConnectAccount = (): any => {
    return this.http
      .get('/office/accounts')
      .subscribe(this.setStripeConnectAccount);
  };
  updateStripeConnectAccount = (stripeConnectAccount: any) => {
    return this.http.put('/office/accounts', stripeConnectAccount);
  };
  createStripeConnectAccount = (accountType) => {
    return this.http.post('/office/accounts', { account_type: accountType });
  };
  deleteStripeConnectAccount = () => {
    return this.http.delete('/office/accounts');
  };
  uploadVerificationDocument = (file: any, name: string): Observable<any> => {
    let fD = new FormData();
    fD.append('file', file, file.name);
    fD.append('documentType', name);
    return this.http.post('/office/accounts/upload', fD);
  };
  addExternalAccount = (token): Observable<any> =>
    this.http.post('/office/accounts/external-account', { token: token });

  getStripeAccountStatus = (profileId: Number): Observable<any> =>
    this.http.get('/office/accounts/' + profileId + '/status');

  setCurrentlyDueStripeAccountLinks = (stripeAccountLinks: any) =>
    this.stripeCurrentlyDueAccountLinksSource.next(stripeAccountLinks);
  loadCurrentlyDueStripeAccountLinks = (isOnboarding: boolean): any => {
    return this.http
      .get(
        isOnboarding
          ? '/office/account_links/onboarding/currently-due'
          : '/office/account_links/update/currently-due'
      )
      .subscribe(this.setCurrentlyDueStripeAccountLinks);
  };

  setEventuallyDueStripeAccountLinks = (stripeAccountLinks: any) =>
    this.stripeEventuallyDueAccountLinksSource.next(stripeAccountLinks);
  loadEventuallyDueStripeAccountLinks = (isOnboarding: boolean): any => {
    return this.http
      .get(
        isOnboarding
          ? '/office/account_links/onboarding/eventually-due'
          : '/office/account_links/update/eventually-due'
      )
      .subscribe(this.setEventuallyDueStripeAccountLinks);
  };

  // Invoices
  private isCreateInvoiceModalVisibleSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCreateInvoiceModalVisible: Observable<any> =
    this.isCreateInvoiceModalVisibleSource.asObservable();

  toggleCreateInvoiceModal = (show: boolean) => {
    this.isCreateInvoiceModalVisibleSource.next(show);
  };

  saveInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/', { invoice: invoice });
  updateInvoice = (invoice: Invoice): Observable<any> =>
    this.http.put('/office/invoices/' + invoice.id, invoice);
  cancelInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/cancel', {});
  refundInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/refund', {});
  remindInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/remind', {});
  markInvoiceAsPaid = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/paid', {});
  createInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/', { invoice: invoice });
  getInvoice = (id: number): Observable<any> =>
    this.http.get('/office/invoices/' + id);
  sendInvoice = (invoice: Invoice): Observable<any> =>
    this.http.post('/office/invoices/' + invoice.id + '/send', invoice);
  deleteInvoice = (invoice: Invoice) =>
    this.http.delete('/office/invoices/' + invoice.id);
  downloadInvoicePDF = (id: number) =>
    this.http.get('/office/invoices/' + id + '/pdf').subscribe((response) => {
      if (response.status == 'success') {
        window.location = response.pdf;
      }
      return response;
    });
  getHostedLink = (id: number) =>
    this.http.get('/office/invoices/' + id + '/link');

  // Invoice Modals
  private currentInvoiceActionSource: BehaviorSubject<INVOICE_ACTIONS> =
    new BehaviorSubject<INVOICE_ACTIONS>(null);
  public currentInvoiceAction: Observable<any> =
    this.currentInvoiceActionSource.asObservable();
  setCurrentInvoiceAction = (action: INVOICE_ACTIONS) =>
    this.currentInvoiceActionSource.next(action);

  // Dashboard
  getBalance = (): Observable<any> =>
    this.http.get('/office/dashboard/balance');
  getEvents = (): Observable<any> =>
    this.http.get('/office/dashboard/event-list');

  // Invoices
  private invoicesSource: BehaviorSubject<Invoice[]> = new BehaviorSubject<
    Invoice[]
  >(null);
  public invoices: Observable<any> = this.invoicesSource.asObservable();
  getInvoices = () =>
    this.http.get('/office/dashboard/invoice-list').subscribe((invoices) => {
      this.invoicesSource.next(invoices);
    });

  // Current Invoice
  private currentInvoiceSource: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public currentInvoice: Observable<any> =
    this.currentInvoiceSource.asObservable();

  setCurrentInvoice = (invoice: Invoice) =>
    this.currentInvoiceSource.next(invoice);
  removeCurrentInvoice = () => this.setCurrentInvoice(null);
  getCurrentInvoice = (id: number): any =>
    this.http.get('/office/invoices/' + id).subscribe(this.setCurrentInvoice);
  removeInvoiceItem = (id: number): Observable<any> =>
    this.http.delete('/office/invoice-items/' + id);

  //

  private unbilledClientAppointmentsSource: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public unbilledClientAppointment: Observable<any> =
    this.unbilledClientAppointmentsSource.asObservable();

  getUnbilledClientAppointments = (id: number) =>
    this.http
      .get('/office/appointments/' + id + '/unbilled')
      .subscribe((response) =>
        this.unbilledClientAppointmentsSource.next(response)
      );
  ignoreUnbilledItem = (
    client_id: number,
    id: number,
    isAppointment: boolean
  ): Observable<any> =>
    this.http.post('/office/appointments/' + client_id + '/ignore_unbilled', {
      id: id,
      is_appointment: isAppointment,
    });

  getClientDetails = (invoice: Invoice) =>
    this.http.get('/office/clients/' + invoice.id);
  updateClientDetails = (userid: number, invoiceClient: InvoiceClient) =>
    this.http.post('/office/clients/' + userid, { client: invoiceClient });

  //Taxrates
  getTaxrates = (): Observable<any> => this.http.get('/office/taxrates');
  saveTaxrate = (taxrate: any): Observable<any> =>
    this.http.post('/office/taxrates', taxrate);
  updateTaxrate = (taxrate: any): Observable<any> =>
    this.http.put('/office/taxrates', taxrate);

  // Invoice Settings
  getInvoiceSettings = (): Observable<any> =>
    this.http.get('/office/invoice-settings');
  updateInvoiceSettings = (invoiceSettings: any): Observable<any> =>
    this.http.put('/office/invoice-settings', invoiceSettings);

  // Balances
  private accountBalancesSource: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public accountBalances: Observable<any> =
    this.accountBalancesSource.asObservable();

  loadAccountBalance = () => {
    this.http.get('/office/dashboard/balance').subscribe((balances) => {
      this.accountBalancesSource.next(balances);
    });
  };

  // Payouts
  private showPaymentModalSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showPaymentModal: Observable<any> =
    this.showPaymentModalSource.asObservable();
  requestPayout = (flag: boolean = true) =>
    this.showPaymentModalSource.next(flag);
  createPayout = (
    amount: number,
    currency: string,
    destination: string
  ): Observable<any> =>
    this.http.post('/office/dashboard/payout', {
      amount: amount,
      currency: currency,
      destination: destination,
    });

  // Amount
  getMonthlyEarnings = (): Observable<any> =>
    this.http.get('/office/dashboard/earnings');

  setStripeConnectClientSecretSource = (clientSecret: string | undefined) =>
    this.stripeConnectClientSecretSource.next(clientSecret);

  fetchStripeConnectClientSecret(): Observable<string | undefined> {
    return this.http
      .post('/office/account-session', {})
      .pipe(
        tap((response) => {
          console.log(response);
        })
      )
      .pipe(
        map((response) => {
          if (response.error) {
            // Handle client-side errors here
            console.log('An error occurred:', response.error);
            return null;
          }
          return response.client_secret;
        })
      )
      .pipe(
        tap((clientSecret) => {
          this.setStripeConnectClientSecretSource(clientSecret);
        })
      );
  }
}
