import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { NotificationsService } from './notifications.service';

export const notificationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const notificationsService = inject(NotificationsService);
  notificationsService.ensureBrowserApiPermission();
  return true; // Always allow navigation
};
