import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
  standalone: true,
})
export class LoadingAnimationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
