<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content dialog-content">
    <div class="dialog">
      <app-profile-picture
        class="dialog-image"
        [variant]="'thumbnail'"
        [profile_id]="therapistProfileId"
      ></app-profile-picture>
      <div class="dialog-text">
        <h3>{{ otherUser?.name }} {{ "call.is_calling_you" | translate }}</h3>
        <div class="call-buttons">
          <button
            class="button is-danger is-rounded"
            (click)="declineIncomingCall()"
          >
            {{ "call.decline" | translate }}
          </button>
          <button
            class="button is-primary is-rounded"
            (click)="acceptIncomingCall()"
          >
            {{ "call.accept" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
