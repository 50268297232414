import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupportRequest } from 'src/app/entities/supportReuest.model';
import { GenericHttpService } from './Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor(private http: GenericHttpService) {}

  private showModalSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);
  public showModal: Observable<boolean> = this.showModalSource.asObservable();

  showReportModal() {
    this.showModalSource.next(true);
  }
  hideReportModal() {
    this.showModalSource.next(false);
  }

  sendSupportRequest(supportRequest: SupportRequest): Observable<any> {
    return this.http.post('/support/', supportRequest);
  }
}
