import { Injectable } from '@angular/core';
import { Alert, AlertType } from 'src/app/entities/alert.model';
import { Subject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private router: Router) {
    // Clear alert messages on route change unless 'keepAfterRouteChange' flag is true

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.clearAlerts();
        }
      }
    });
  }

  private keepAfterRouteChange: boolean;
  private alertSubject = new Subject<Alert>();

  public alertObservable = this.alertSubject.asObservable();

  clearAlerts = (): void => this.alertSubject.next(null);

  success(
    message: string,
    title?: string,
    keepAfterRouteChange = false,
    buttons?,
    autoHide = true
  ) {
    this.alert(
      AlertType.SUCCESS,
      message,
      title,
      keepAfterRouteChange,
      buttons,
      autoHide
    );
  }
  error(
    message: string,
    title?: string,
    keepAfterRouteChange = false,
    buttons?,
    autoHide = true
  ) {
    this.alert(
      AlertType.ERROR,
      message,
      title,
      keepAfterRouteChange,
      buttons,
      autoHide
    );
  }
  info(
    message: string,
    title?: string,
    keepAfterRouteChange = false,
    buttons?,
    autoHide = true
  ) {
    this.alert(
      AlertType.INFO,
      message,
      title,
      keepAfterRouteChange,
      buttons,
      autoHide
    );
  }
  warn(
    message: string,
    title?: string,
    keepAfterRouteChange = false,
    buttons?,
    autoHide = true
  ) {
    this.alert(
      AlertType.WARNING,
      message,
      title,
      keepAfterRouteChange,
      buttons,
      autoHide
    );
  }
  alert(
    type: AlertType,
    message: string,
    title?: string,
    keepAfterRouteChange = false,
    buttons?,
    autoHide = true
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.alertSubject.next(<Alert>{
      type: type,
      message: message,
      title: title,
      buttons: buttons,
      autoHide: autoHide,
    });
  }
}
