import { __awaiter, __generator, __read } from "tslib";
import { getOldCookieName } from '@amplitude/analytics-client-common';
import { createCookieStorage, getDefaultConfig } from '../config';
export var parseLegacyCookies = function (apiKey, options) {
  return __awaiter(void 0, void 0, void 0, function () {
    var storage, oldCookieName, cookies, _a, deviceId, userId, optOut, sessionId, lastEventTime, lastEventId;
    var _b;
    return __generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          return [4 /*yield*/, createCookieStorage(options)];
        case 1:
          storage = _c.sent();
          oldCookieName = getOldCookieName(apiKey);
          return [4 /*yield*/, storage.getRaw(oldCookieName)];
        case 2:
          cookies = _c.sent();
          if (!cookies) {
            return [2 /*return*/, {
              optOut: false
            }];
          }
          if (!((_b = options.cookieUpgrade) !== null && _b !== void 0 ? _b : getDefaultConfig().cookieUpgrade)) return [3 /*break*/, 4];
          return [4 /*yield*/, storage.remove(oldCookieName)];
        case 3:
          _c.sent();
          _c.label = 4;
        case 4:
          _a = __read(cookies.split('.'), 6), deviceId = _a[0], userId = _a[1], optOut = _a[2], sessionId = _a[3], lastEventTime = _a[4], lastEventId = _a[5];
          return [2 /*return*/, {
            deviceId: deviceId,
            userId: decode(userId),
            sessionId: parseTime(sessionId),
            lastEventId: parseTime(lastEventId),
            lastEventTime: parseTime(lastEventTime),
            optOut: Boolean(optOut)
          }];
      }
    });
  });
};
export var parseTime = function (num) {
  var integer = parseInt(num, 32);
  if (isNaN(integer)) {
    return undefined;
  }
  return integer;
};
export var decode = function (value) {
  if (!atob || !escape || !value) {
    return undefined;
  }
  try {
    return decodeURIComponent(escape(atob(value)));
  } catch (_a) {
    return undefined;
  }
};
