import { Component, Input } from '@angular/core';
import { NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MatchedByIcComponent } from '../badges/matched-by-ic/matched-by-ic.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
  standalone: true,
  imports: [NgIf, NgOptimizedImage, NgStyle, MatchedByIcComponent, MatIcon],
})
export class AvatarImageComponent {
  @Input() src: string;
  @Input() alt: string = 'Profile Picture';
  @Input() showMatchedBadge: boolean = false;
  @Input() showNotification: boolean = false;
  @Input() loading: boolean = false; // Added to control loading state
}
