import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { User } from '../../../entities/user.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor(private route: ActivatedRoute) {}

  hasUserAccessToTrialAndEventuallyApproved(user: User): boolean {
    return (
      environment.isTrialAndEventuallyApprovedEnabled && user.is_feature_tester
    );
  }

  isUsingGetStreamChat(): boolean {
    const legacyChat = this.route.snapshot.queryParamMap.get('legacy_chat');
    return environment.name !== 'production' && legacyChat !== '1';
  }
}
