import { Injectable } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { distinctUntilChanged, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
// Knows about all local states related to auth
export class AuthStatesManager {
  constructor(
    private profileService: ProfileService,
    private officeService: OfficeService
  ) {}

  public clearLocalState() {
    this.clearCurrentUser();
    this.clearStorage();
  }

  private clearCurrentUser() {
    this.profileService.setProfile(null);
    this.officeService.setStripeConnectAccount(null);
    this.officeService.setCurrentInvoice(null);
  }

  private clearStorage() {
    sessionStorage.removeItem('ic_pw');
    this.removeLegacyTokenData();
  }

  private removeLegacyTokenData() {
    // this is from when we used angular-token. Will usually be a no-op later
    localStorage.removeItem('client');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiry');
    localStorage.removeItem('tokenType');
  }
}
