import { __awaiter, __generator, __read } from "tslib";
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PluginType } from '@amplitude/analytics-types';
import { DEFAULT_EVENT_PREFIX, DEFAULT_PAGE_VIEW_EVENT } from '../constants';
var eventPropertyMap = {
  page_domain: "".concat(DEFAULT_EVENT_PREFIX, " Page Domain"),
  page_location: "".concat(DEFAULT_EVENT_PREFIX, " Page Location"),
  page_path: "".concat(DEFAULT_EVENT_PREFIX, " Page Path"),
  page_title: "".concat(DEFAULT_EVENT_PREFIX, " Page Title"),
  page_url: "".concat(DEFAULT_EVENT_PREFIX, " Page URL")
};
export var defaultPageViewEventEnrichment = function () {
  var name = '@amplitude/plugin-default-page-view-event-enrichment-browser';
  var type = PluginType.ENRICHMENT;
  var setup = function () {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, undefined];
      });
    });
  };
  var execute = function (event) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        if (event.event_type === DEFAULT_PAGE_VIEW_EVENT && event.event_properties) {
          event.event_properties = Object.entries(event.event_properties).reduce(function (acc, _a) {
            var _b = __read(_a, 2),
              key = _b[0],
              value = _b[1];
            var transformedPropertyName = eventPropertyMap[key];
            if (transformedPropertyName) {
              acc[transformedPropertyName] = value;
            } else {
              acc[key] = value;
            }
            return acc;
          }, {});
        }
        return [2 /*return*/, event];
      });
    });
  };
  return {
    name: name,
    type: type,
    setup: setup,
    execute: execute
  };
};
