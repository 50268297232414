export const defaults = {
  retain_coupon: 'P-Ux65-DP',
  stripe_available_countries: [
    'Australia',
    'Austria',
    'Belgium',
    'Bulgaria',
    'Canada',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Czechia', // we might not need the above, this is how our geocoder calls it for now
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Gibraltar',
    'Greece',
    'Hong Kong',
    'Hungary',
    'Ireland',
    'Italy',
    'Japan',
    'Latvia',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Malaysia',
    'Malta',
    'Mexico',
    'Netherlands',
    'New Zealand',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Thailand',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
  ],
};
