import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, filter, take, catchError } from 'rxjs/operators';
import { SharedService } from '../shared/services/shared.service';
import { createApplyLink } from '../shared/helpers/navigation_helper';
import { MonitoringService } from '../shared/services/monitoring/monitoring.service';
import { AlertService } from '../shared/components/alert/service/alert.service';

export const preApprovalGuard: CanActivateFn = (): Observable<boolean> => {
  const sharedService = inject(SharedService);
  const router = inject(Router);
  const alertService = inject(AlertService);

  return sharedService.currentUser.pipe(
    filter((user) => user !== null), // Wait for a non-null user
    take(1),
    map((user) => {
      if (!!user.application_status && user.application_status !== 'accepted') {
        window.location.href = createApplyLink(user.locale);
        return false;
      }
      return true;
    }),
    catchError((err) => {
      MonitoringService.captureException(err);
      alertService.error(
        'common.generic_error.message',
        'common.generic_error.title',
        false,
        undefined,
        false
      );
      return of(false);
    })
  );
};
