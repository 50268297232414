import { __assign, __awaiter, __extends, __generator, __values } from "tslib";
import { TransportType } from '@amplitude/analytics-types';
import { Config, MemoryStorage, UUID } from '@amplitude/analytics-core';
import { CookieStorage, getCookieName, FetchTransport } from '@amplitude/analytics-client-common';
import { LocalStorage } from './storage/local-storage';
import { XHRTransport } from './transports/xhr';
import { SendBeaconTransport } from './transports/send-beacon';
export var getDefaultConfig = function () {
  var cookieStorage = new MemoryStorage();
  var trackingOptions = {
    deviceManufacturer: true,
    deviceModel: true,
    ipAddress: true,
    language: true,
    osName: true,
    osVersion: true,
    platform: true
  };
  return {
    cookieExpiration: 365,
    cookieSameSite: 'Lax',
    cookieSecure: false,
    cookieStorage: cookieStorage,
    cookieUpgrade: true,
    disableCookies: false,
    domain: '',
    sessionTimeout: 30 * 60 * 1000,
    trackingOptions: trackingOptions,
    transportProvider: new FetchTransport()
  };
};
var BrowserConfig = /** @class */function (_super) {
  __extends(BrowserConfig, _super);
  function BrowserConfig(apiKey, options) {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var defaultConfig = getDefaultConfig();
    _this = _super.call(this, __assign(__assign({
      flushIntervalMillis: 1000,
      flushMaxRetries: 5,
      flushQueueSize: 30,
      transportProvider: defaultConfig.transportProvider
    }, options), {
      apiKey: apiKey
    })) || this;
    _this._optOut = false;
    // NOTE: Define `cookieStorage` first to persist user session
    // user session properties expect `cookieStorage` to be defined
    _this.cookieStorage = (_a = options === null || options === void 0 ? void 0 : options.cookieStorage) !== null && _a !== void 0 ? _a : defaultConfig.cookieStorage;
    _this.deviceId = options === null || options === void 0 ? void 0 : options.deviceId;
    _this.lastEventId = options === null || options === void 0 ? void 0 : options.lastEventId;
    _this.lastEventTime = options === null || options === void 0 ? void 0 : options.lastEventTime;
    _this.optOut = Boolean(options === null || options === void 0 ? void 0 : options.optOut);
    _this.sessionId = options === null || options === void 0 ? void 0 : options.sessionId;
    _this.userId = options === null || options === void 0 ? void 0 : options.userId;
    _this.appVersion = options === null || options === void 0 ? void 0 : options.appVersion;
    _this.attribution = options === null || options === void 0 ? void 0 : options.attribution;
    _this.cookieExpiration = (_b = options === null || options === void 0 ? void 0 : options.cookieExpiration) !== null && _b !== void 0 ? _b : defaultConfig.cookieExpiration;
    _this.cookieSameSite = (_c = options === null || options === void 0 ? void 0 : options.cookieSameSite) !== null && _c !== void 0 ? _c : defaultConfig.cookieSameSite;
    _this.cookieSecure = (_d = options === null || options === void 0 ? void 0 : options.cookieSecure) !== null && _d !== void 0 ? _d : defaultConfig.cookieSecure;
    _this.cookieUpgrade = (_e = options === null || options === void 0 ? void 0 : options.cookieUpgrade) !== null && _e !== void 0 ? _e : defaultConfig.cookieUpgrade;
    _this.defaultTracking = options === null || options === void 0 ? void 0 : options.defaultTracking;
    _this.disableCookies = (_f = options === null || options === void 0 ? void 0 : options.disableCookies) !== null && _f !== void 0 ? _f : defaultConfig.disableCookies;
    _this.defaultTracking = options === null || options === void 0 ? void 0 : options.defaultTracking;
    _this.domain = (_g = options === null || options === void 0 ? void 0 : options.domain) !== null && _g !== void 0 ? _g : defaultConfig.domain;
    _this.partnerId = options === null || options === void 0 ? void 0 : options.partnerId;
    _this.sessionTimeout = (_h = options === null || options === void 0 ? void 0 : options.sessionTimeout) !== null && _h !== void 0 ? _h : defaultConfig.sessionTimeout;
    _this.trackingOptions = (_j = options === null || options === void 0 ? void 0 : options.trackingOptions) !== null && _j !== void 0 ? _j : defaultConfig.trackingOptions;
    return _this;
  }
  Object.defineProperty(BrowserConfig.prototype, "deviceId", {
    get: function () {
      return this._deviceId;
    },
    set: function (deviceId) {
      if (this._deviceId !== deviceId) {
        this._deviceId = deviceId;
        this.updateStorage();
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(BrowserConfig.prototype, "userId", {
    get: function () {
      return this._userId;
    },
    set: function (userId) {
      if (this._userId !== userId) {
        this._userId = userId;
        this.updateStorage();
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(BrowserConfig.prototype, "sessionId", {
    get: function () {
      return this._sessionId;
    },
    set: function (sessionId) {
      if (this._sessionId !== sessionId) {
        this._sessionId = sessionId;
        this.updateStorage();
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(BrowserConfig.prototype, "optOut", {
    get: function () {
      return this._optOut;
    },
    set: function (optOut) {
      if (this._optOut !== optOut) {
        this._optOut = optOut;
        this.updateStorage();
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(BrowserConfig.prototype, "lastEventTime", {
    get: function () {
      return this._lastEventTime;
    },
    set: function (lastEventTime) {
      if (this._lastEventTime !== lastEventTime) {
        this._lastEventTime = lastEventTime;
        this.updateStorage();
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(BrowserConfig.prototype, "lastEventId", {
    get: function () {
      return this._lastEventId;
    },
    set: function (lastEventId) {
      if (this._lastEventId !== lastEventId) {
        this._lastEventId = lastEventId;
        this.updateStorage();
      }
    },
    enumerable: false,
    configurable: true
  });
  BrowserConfig.prototype.updateStorage = function () {
    var _a;
    var cache = {
      deviceId: this._deviceId,
      userId: this._userId,
      sessionId: this._sessionId,
      optOut: this._optOut,
      lastEventTime: this._lastEventTime,
      lastEventId: this._lastEventId
    };
    void ((_a = this.cookieStorage) === null || _a === void 0 ? void 0 : _a.set(getCookieName(this.apiKey), cache));
  };
  return BrowserConfig;
}(Config);
export { BrowserConfig };
export var useBrowserConfig = function (apiKey, options) {
  return __awaiter(void 0, void 0, void 0, function () {
    var defaultConfig, deviceId, lastEventId, lastEventTime, optOut, sessionId, userId, cookieStorage, domain, _a, _b, _c;
    var _d;
    var _e, _f;
    return __generator(this, function (_g) {
      switch (_g.label) {
        case 0:
          defaultConfig = getDefaultConfig();
          deviceId = (_e = options === null || options === void 0 ? void 0 : options.deviceId) !== null && _e !== void 0 ? _e : UUID();
          lastEventId = options === null || options === void 0 ? void 0 : options.lastEventId;
          lastEventTime = options === null || options === void 0 ? void 0 : options.lastEventTime;
          optOut = options === null || options === void 0 ? void 0 : options.optOut;
          sessionId = options === null || options === void 0 ? void 0 : options.sessionId;
          userId = options === null || options === void 0 ? void 0 : options.userId;
          cookieStorage = options === null || options === void 0 ? void 0 : options.cookieStorage;
          domain = options === null || options === void 0 ? void 0 : options.domain;
          _a = BrowserConfig.bind;
          _b = [void 0, apiKey];
          _c = [__assign({}, options)];
          _d = {
            cookieStorage: cookieStorage,
            deviceId: deviceId,
            domain: domain,
            lastEventId: lastEventId,
            lastEventTime: lastEventTime,
            optOut: optOut,
            sessionId: sessionId
          };
          return [4 /*yield*/, createEventsStorage(options)];
        case 1:
          return [2 /*return*/, new (_a.apply(BrowserConfig, _b.concat([__assign.apply(void 0, _c.concat([(_d.storageProvider = _g.sent(), _d.trackingOptions = __assign(__assign({}, defaultConfig.trackingOptions), options === null || options === void 0 ? void 0 : options.trackingOptions), _d.transportProvider = (_f = options === null || options === void 0 ? void 0 : options.transportProvider) !== null && _f !== void 0 ? _f : createTransport(options === null || options === void 0 ? void 0 : options.transport), _d.userId = userId, _d)]))])))()];
      }
    });
  });
};
export var createCookieStorage = function (overrides, baseConfig) {
  if (baseConfig === void 0) {
    baseConfig = getDefaultConfig();
  }
  return __awaiter(void 0, void 0, void 0, function () {
    var options, cookieStorage, _a;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          options = __assign(__assign({}, baseConfig), overrides);
          cookieStorage = overrides === null || overrides === void 0 ? void 0 : overrides.cookieStorage;
          _a = !cookieStorage;
          if (_a) return [3 /*break*/, 2];
          return [4 /*yield*/, cookieStorage.isEnabled()];
        case 1:
          _a = !_b.sent();
          _b.label = 2;
        case 2:
          if (_a) {
            return [2 /*return*/, createFlexibleStorage(options)];
          }
          return [2 /*return*/, cookieStorage];
      }
    });
  });
};
var createFlexibleStorage = function (options) {
  return __awaiter(void 0, void 0, void 0, function () {
    var storage, _a;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          storage = new CookieStorage({
            domain: options.domain,
            expirationDays: options.cookieExpiration,
            sameSite: options.cookieSameSite,
            secure: options.cookieSecure
          });
          _a = options.disableCookies;
          if (_a) return [3 /*break*/, 2];
          return [4 /*yield*/, storage.isEnabled()];
        case 1:
          _a = !_b.sent();
          _b.label = 2;
        case 2:
          if (!_a) return [3 /*break*/, 4];
          storage = new LocalStorage();
          return [4 /*yield*/, storage.isEnabled()];
        case 3:
          if (!_b.sent()) {
            storage = new MemoryStorage();
          }
          _b.label = 4;
        case 4:
          return [2 /*return*/, storage];
      }
    });
  });
};
export var createEventsStorage = function (overrides) {
  return __awaiter(void 0, void 0, void 0, function () {
    var hasStorageProviderProperty, loggerProvider, _a, _b, storage, _c, e_1_1;
    var e_1, _d;
    return __generator(this, function (_e) {
      switch (_e.label) {
        case 0:
          hasStorageProviderProperty = overrides && Object.prototype.hasOwnProperty.call(overrides, 'storageProvider');
          loggerProvider = overrides && overrides.loggerProvider;
          if (!(!hasStorageProviderProperty || overrides.storageProvider)) return [3 /*break*/, 9];
          _e.label = 1;
        case 1:
          _e.trys.push([1, 7, 8, 9]);
          _a = __values([overrides === null || overrides === void 0 ? void 0 : overrides.storageProvider, new LocalStorage({
            loggerProvider: loggerProvider
          })]), _b = _a.next();
          _e.label = 2;
        case 2:
          if (!!_b.done) return [3 /*break*/, 6];
          storage = _b.value;
          _c = storage;
          if (!_c) return [3 /*break*/, 4];
          return [4 /*yield*/, storage.isEnabled()];
        case 3:
          _c = _e.sent();
          _e.label = 4;
        case 4:
          if (_c) {
            return [2 /*return*/, storage];
          }
          _e.label = 5;
        case 5:
          _b = _a.next();
          return [3 /*break*/, 2];
        case 6:
          return [3 /*break*/, 9];
        case 7:
          e_1_1 = _e.sent();
          e_1 = {
            error: e_1_1
          };
          return [3 /*break*/, 9];
        case 8:
          try {
            if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
          } finally {
            if (e_1) throw e_1.error;
          }
          return [7 /*endfinally*/];
        case 9:
          return [2 /*return*/, undefined];
      }
    });
  });
};
export var createTransport = function (transport) {
  if (transport === TransportType.XHR) {
    return new XHRTransport();
  }
  if (transport === TransportType.SendBeacon) {
    return new SendBeaconTransport();
  }
  return getDefaultConfig().transportProvider;
};
export var getTopLevelDomain = function (url) {
  return __awaiter(void 0, void 0, void 0, function () {
    var host, parts, levels, storageKey, i, i, domain, options, storage, value;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4 /*yield*/, new CookieStorage().isEnabled()];
        case 1:
          if (!_a.sent() || !url && (typeof location === 'undefined' || !location.hostname)) {
            return [2 /*return*/, ''];
          }
          host = url !== null && url !== void 0 ? url : location.hostname;
          parts = host.split('.');
          levels = [];
          storageKey = 'AMP_TLDTEST';
          for (i = parts.length - 2; i >= 0; --i) {
            levels.push(parts.slice(i).join('.'));
          }
          i = 0;
          _a.label = 2;
        case 2:
          if (!(i < levels.length)) return [3 /*break*/, 7];
          domain = levels[i];
          options = {
            domain: '.' + domain
          };
          storage = new CookieStorage(options);
          return [4 /*yield*/, storage.set(storageKey, 1)];
        case 3:
          _a.sent();
          return [4 /*yield*/, storage.get(storageKey)];
        case 4:
          value = _a.sent();
          if (!value) return [3 /*break*/, 6];
          return [4 /*yield*/, storage.remove(storageKey)];
        case 5:
          _a.sent();
          return [2 /*return*/, '.' + domain];
        case 6:
          i++;
          return [3 /*break*/, 2];
        case 7:
          return [2 /*return*/, ''];
      }
    });
  });
};
