<image-cropper
  #cropper
  [imageChangedEvent]="imageChangedEvent"
  [roundCropper]="true"
  [autoCrop]="false"
  [maintainAspectRatio]="true"
  format="png"
  (imageCropped)="imageCropped($event)"
></image-cropper>
<div class="cropper-actions">
  <button (click)="removeImage()" class="button is-outlined">Cancel</button>
  <button (click)="saveImage()" class="button is-primary">Save</button>
</div>
